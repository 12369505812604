import { Spin } from '@nbit/arco'
import { useRafTimeout } from 'ahooks'
import { useLayoutStore } from '@/store/layout'

export default function LayoutGlobalLoading() {
  const { setLayoutLading } = useLayoutStore()

  // 5s 后必定关闭
  useRafTimeout(() => {
    setLayoutLading(false)
  }, 5000)

  return (
    <div
      style={{
        position: 'fixed',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        zIndex: 9999,
        background: '#181B22',
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Spin />
    </div>
  )
}
